import { Suspense, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { useAuth, useCore, useIagona, useConfig } from 'context';

import { Loader } from 'components';
import { RemainingNotification } from 'components/common/sub';

export const Transaction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getHardwareStatus, basket, reset, saveTransaction } = useCore();
  const { client, setClient } = useAuth();
  const { etablissement, config } = useConfig();
  const { startTransaction, stopTransaction, cancelCalls, stopInput } =
    useIagona();

  const [remaining, setRemaining] = useState<number>(60);
  const [loading, setLoading] = useState<boolean>(false);

  const getTimeout = (pathname: string) => {
    if (['/checkout/payment', '/scan'].includes(pathname)) return 300_000;
    return 60_000;
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: getTimeout(location.pathname),
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(getRemainingTime() / 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [getRemainingTime]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') onIdle();
  }, [remaining]);

  const onIdle = async () => {
    if (!['/', '/screensaver'].includes(location.pathname) && remaining <= 1) {
      await stopInput();
      await reset();
      navigate('/');
    }
  };

  const showModal = () => {
    return (
      !['/', '/screensaver'].includes(location.pathname) && remaining <= 20
    );
  };

  useEffect(() => {
    if (!client && !['/', '/auth'].includes(location.pathname)) {
      reset();
      navigate('/');
    }
  }, [client, basket]);

  useEffect(() => {
    if (!['/', '/screensaver'].includes(location.pathname)) {
      handleInit();
    }

    return () => {
      cancelCalls();
      handleUnmount();
    };
  }, []);

  const handleInit = async () => {
    setLoading(true);

    const result = await startTransaction();
    if (!result) {
      setLoading(false);
      navigate('/');
    }
    const now = new Date();
    const formattedDate = format(now, 'dd/MM/yyyy');
    const formattedTime = format(now, 'HH:mm:ss');

    await saveTransaction({
      dateDebut: formattedDate,
      heureDebut: formattedTime,
      etablissement: { oid: etablissement?.oid || 0 },
      transactionValidee: false,
      operateur: { oid: config?.operateur?.oid || 0 },
    });

    setLoading(false);
    getHardwareStatus();
  };

  const handleUnmount = async () => {
    setLoading(true);

    await getHardwareStatus();
    await stopTransaction();

    setClient(undefined);
  };

  if (loading) return <Loader />;

  return (
    <>
      <RemainingNotification remaining={remaining} open={showModal()} />
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </>
  );
};
