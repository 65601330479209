import React, { useEffect, useMemo, useState, useRef } from 'react';
import { format } from 'date-fns';

import {
  addBalneoAbonnement,
  addBalneoArticle,
  addBalneoPrestation,
  createBalneoVente,
  getBalneoClientEncours,
} from 'features/checkout/stub/balneo';
import {
  deleteLignesFacture,
  getFacture,
  updateLigneFacture,
  removeCodePromo,
  applyCodePromo,
  searchCadeaux,
  getChequeCadeauByOid,
  registerTransaction,
} from 'features/checkout/stub';

import { useAuth } from 'context/auth/auth.context';
import { useIagona } from 'context/iagona/iagona.context';
import { useData } from 'context/data/data.context';

import {
  CommonCartInput,
  Invoice,
  InvoiceLine,
  NegativeLine,
} from 'types/checkout';
import { EtatProduitCadeau } from 'types/produitsCadeau';

import { formatJsonData } from 'utils/cart';
import { htmlDecimalEntitieDecode } from 'utils/tools';
import { Button, Modal } from 'components/ui';
import { SecretModal } from 'components/iagona/sub/secret.modal';
import { InfosTransaction } from 'types/core';
import { CoreContext, CoreContextProps } from './core.context';

export type Alerts = {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
};

export const CoreProvider = ({ children }: { children: React.ReactNode }) => {
  const { client, setClient, badge } = useAuth();
  const { modePaiements } = useData();
  const { getStatus, cancelCalls } = useIagona();
  const infosTransactionRef = useRef<InfosTransaction>();

  const [basket, setBasket] = useState<Invoice>();
  const [alerts, setAlerts] = useState<Alerts>();
  const [hardwareError, setHardwareError] = useState<boolean>(false);
  const [negativeLines, setNegativeLines] = useState<NegativeLine[]>([]);
  const [clientAuthenticated, setClientAuthenticated] =
    useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>('');
  const [confirmPromise, setConfirmPromise] = useState<{
    resolve: () => void;
    reject: () => void;
  }>();
  const [infosTransaction, setInfosTransaction] = useState<InfosTransaction>();

  useEffect(() => {
    if (client?.client?.oid) fetchEncours();
  }, [client]);

  const fetchEncours = async () => {
    const encours: Invoice = await getBalneoClientEncours(
      client?.client?.oid as number,
    );
    setInfosTransaction({
      ...infosTransaction,
      client: { oid: client?.client?.oid as number },
      facture: { oid: encours.oid },
    });
    await cleanBasket(encours);
    setBasket({ ...encours, total: encours.montantNetTTC });
  };

  useEffect(() => {
    refreshBasket();
  }, [negativeLines]);

  useEffect(() => {
    getHardwareStatus();
    const interval = setInterval(() => {
      getHardwareStatus();
    }, 4 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const getHardwareStatus = async () => {
    if (['/', '/screensaver'].includes(window.location.pathname)) return;
    const status = await getStatus();
    if (!status) {
      setHardwareError(true);
    } else {
      setHardwareError(false);
    }
  };

  useEffect(() => {
    infosTransactionRef.current = infosTransaction;
  }, [infosTransaction]);

  const saveInfoTransaction = (data: InfosTransaction) => {
    setInfosTransaction({ ...infosTransaction, ...data });
  };

  const handleRegisterTransaction = async () => {
    const currentInfosTransaction = infosTransactionRef.current;
    const now = new Date();
    const formattedDate = format(now, 'dd/MM/yyyy');
    const formattedTime = format(now, 'HH:mm:ss');

    if (currentInfosTransaction) {
      try {
        await registerTransaction({
          dateDebut: currentInfosTransaction.dateDebut,
          heureDebut: currentInfosTransaction.heureDebut,
          dateFin: formattedDate,
          heureFin: formattedTime,
          facture: currentInfosTransaction.facture?.oid
            ? { oid: currentInfosTransaction.facture.oid }
            : null,
          client: currentInfosTransaction.client?.oid
            ? { oid: currentInfosTransaction.client.oid }
            : null,
          operateur: { oid: currentInfosTransaction.operateur?.oid || 0 },
          etablissement: {
            oid: currentInfosTransaction.etablissement?.oid || 0,
          },
          transactionValidee:
            currentInfosTransaction?.transactionValidee || false,
        });
      } catch (error) {
        console.error('Failed to register transaction:', error);
      }
    }
  };

  const reset = async () => {
    await handleRegisterTransaction();
    await cleanBasket(basket as Invoice);
    setBasket(undefined);
    setNegativeLines([]);
    setClientAuthenticated(false);
    cancelCalls();
    setClient(undefined);
    setInfosTransaction(undefined);
  };

  const cleanBasket = async (invoice: Invoice) => {
    if (!invoice?.oid) return;
    try {
      if (invoice.codePromo?.oid) await removeCodePromo(invoice.oid);
      if (invoice.lignes?.length)
        await deleteLignesFacture(invoice.lignes.map(({ oid }) => oid));
      setBasket(undefined);
      setNegativeLines([]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    await refreshBasket();
  };

  const refreshBasket = async () => {
    if (!basket?.oid) return;
    const encours = await getFacture(basket.oid);

    const lignes: any[] = encours.lignes.map(
      (
        ligne: InvoiceLine & {
          prestation?: any;
          abonnement?: any;
          article?: any;
        },
      ) => {
        let type = '';
        if (ligne.prestation) type = 'prestation';
        else if (ligne.abonnement) type = 'abonnement';
        else if (ligne.article) type = 'article';

        return {
          ...ligne,
          element: {
            ...ligne.element,
            type,
          },
        };
      },
    );

    const totalNegative: number = Math.round(
      negativeLines.reduce((acc, { amount }) => acc + amount * 100, 0),
    );

    setBasket({
      ...encours,
      total: Math.round(encours.montantNetTTC * 100 - totalNegative) / 100,
      lignes,
    });
  };

  const addProduct = async (input: CommonCartInput) => {
    const { product, ...rest } = input;
    if (!basket?.oid) return;

    // check exist
    const exist = basket.lignes.find(
      ({ element }) =>
        element.type === product.type && element.oid === product.oid,
    );

    if (exist) {
      updateBasketLine({
        ...exist,
        quantite: exist.quantite + (input.quantite || 1),
      });
      return;
    }

    let jsonData = '';

    if (client)
      jsonData = formatJsonData(
        product,
        input.quantite || 1,
        client.client,
        '',
        badge,
      );
    switch (product.type) {
      case 'article':
        await addBalneoArticle(basket.oid, {
          ...rest,
          article: { oid: product.oid },
        });
        break;
      case 'abonnement':
        await addBalneoAbonnement(basket.oid, {
          ...rest,
          abonnement: { oid: product.oid },
          jsonData,
          clients: client ? [{ oid: client.client.oid }] : [],
        });
        break;
      case 'prestation':
        await addBalneoPrestation(basket.oid, {
          ...rest,
          prestation: { oid: product.oid },
          jsonData,
        });
        break;
      default:
        return;
    }
    refreshBasket();
  };

  const addCodePromo = async (code: string) => {
    if (!basket?.oid) return;
    try {
      await applyCodePromo(basket.oid, { code });
      refreshBasket();
    } catch (e: any) {
      setAlerts({
        type: 'error',
        message:
          `${htmlDecimalEntitieDecode(
            e?.response?.headers['x-failure-reason'],
          )}. Rdv à l'accueil pour vous inscrire et profiter de votre réduction.` ||
          'Une erreur est survenue',
      });
    }
  };

  const checkValidity = (amount: number, totalOverride?: number) => {
    const total = totalOverride || basket?.total || 0;
    if (amount > total) return total;
    return amount;
  };

  const addChequeCadeau = async (code: string): Promise<boolean> => {
    try {
      if (!basket?.oid) return Promise.resolve(false);
      const result = await searchCadeaux(code);
      const accepted = result?.bonsCadeaux?.filter(({ type }) =>
        ['CHEQUE_CADEAU_FIXE', 'CHEQUE_CADEAU_LIBRE'].includes(`${type}`),
      );
      if (accepted?.length) {
        const chq = await getChequeCadeauByOid(accepted[0].oid);
        if (chq.etat === EtatProduitCadeau.CONSOMMABLE) {
          setNegativeLines([
            ...negativeLines,
            {
              key: code,
              type: 'CHEQUE_CADEAU',
              libelle: `Chèque cadeau ${chq.code}`,
              amount: checkValidity(
                (chq.montant * 100 - chq.montantUtilise * 100) / 100 || 0,
              ),
              produitCadeau: chq,
            },
          ]);
        }
        return Promise.resolve(true);
      }
      setAlerts({
        type: 'error',
        message: `Nous n'avons pas trouvé de chèque cadeau correspondant à ce code.`,
      });
      return Promise.resolve(false);
    } catch (e: any) {
      setAlerts({
        type: 'error',
        message:
          htmlDecimalEntitieDecode(e?.response?.headers['x-failure-reason']) ||
          'Une erreur est survenue',
      });
      return Promise.resolve(false);
    }
  };

  const removeNegativeLine = (key: string) => {
    setNegativeLines(negativeLines.filter(line => line.key !== key));
    refreshBasket();
  };

  const handleAutoRemoveCC = async () => {
    const cc = negativeLines.find(({ type }) => type === 'CHEQUE_CADEAU');
    if (
      !!cc &&
      (await confirm('Cette action supprimera votre chèque cadeau.'))
    ) {
      if (cc) removeNegativeLine(cc.key);

      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  };

  const updateBasketLine = async (line: InvoiceLine) => {
    if (!basket?.oid) return;
    await handleAutoRemoveCC();
    if (line.quantite < 1) {
      deleteBasketLine(line.oid);
      return;
    }
    let jsonData: string = line.jsonData || '';
    if (client)
      jsonData = formatJsonData(
        line.element,
        line.quantite,
        client.client,
        jsonData,
        badge,
      );
    await updateLigneFacture({ ...line, jsonData });
    refreshBasket();
  };

  const deleteBasketLine = async (lineOid: number | number[]) => {
    if (!basket?.oid || !lineOid) return;

    await handleAutoRemoveCC();
    await deleteLignesFacture(
      typeof lineOid === 'number' ? [lineOid] : lineOid,
    );
    refreshBasket();
  };

  const validateBasket = async (): Promise<string> =>
    new Promise(async resolve => {
      if (!basket?.oid) return;

      const cbMode = modePaiements.find(
        ({ terminalPaiement }) => terminalPaiement,
      );
      const ccMode = modePaiements.find(({ chequeCadeau }) => chequeCadeau);

      if (!cbMode || !ccMode) return;

      const result = await createBalneoVente({
        client: { oid: client?.client?.oid || 0 },
        facture: { oid: basket.oid },
        oidLignes: basket.lignes.map(({ oid }) => oid),
        paiement: {
          montant: basket.montantNetTTC,
          mouvements: [
            ...(negativeLines.length
              ? negativeLines.map(({ amount, produitCadeau }) => ({
                  montant: amount,
                  modePaiement: { oid: ccMode.oid },
                  chequeCadeau: { oid: produitCadeau?.oid },
                }))
              : []),
            {
              montant: basket.total,
              modePaiement: { oid: cbMode.oid },
            },
          ],
        },
      });

      if (result.factures.length) {
        resolve('/checkout/invoice');
      }
    });

  const confirm = async (message?: string): Promise<boolean> => {
    return new Promise(resolve => {
      setConfirmMessage(message || '');
      setConfirmPromise({
        resolve: () => {
          setShowConfirm(false);
          resolve(true);
        },
        reject: () => {
          setShowConfirm(false);
          resolve(false);
        },
      });
      setShowConfirm(true);
    });
  };

  const sendAlert = (message: string) => {
    console.log('sendAlert', message);
    // if (!wsClient.current) return;
    // (wsClient.current as any).sendMessage(
    //   '/app/broadcast-relay',
    //   JSON.stringify({
    //     type: 'UI_ACTION',
    //     data: {
    //       'etablissement.oid': etablissement?.oid,
    //       type: 'BORNE_ALERT',
    //       messageContent: message,
    //     },
    //   }),
    // );
  };

  const handleMessage = (msg: any) => {
    console.log('handleMessage', msg);
  };

  const values: CoreContextProps = useMemo(
    () => ({
      clientAuthenticated,
      setClientAuthenticated,
      basket,
      addProduct,
      negativeLines,
      removeNegativeLine,
      addCodePromo,
      addChequeCadeau,
      updateBasketLine,
      deleteBasketLine,
      confirm,
      reset,
      validateBasket,
      getHardwareStatus,
      sendAlert,
      saveTransaction: saveInfoTransaction,
    }),
    [clientAuthenticated, basket],
  );

  return (
    <CoreContext.Provider value={values}>
      <SecretModal />
      {/* <SockJsClient
        url={process.env.REACT_APP_WS_URL}
        topics={['/broadcast']}
        ref={wsClient}
        onMessage={handleMessage}
      /> */}
      <Modal open={hardwareError}>
        <div className="flex flex-col justify-center items-center gap-4">
          <div className="text-4xl font-bold">Erreur technique</div>
          <div className="text-center">
            Veuillez contacter un membre du personnel pour résoudre le problème.
          </div>
          <Button onClick={() => getHardwareStatus()}>Relancer</Button>
        </div>
      </Modal>
      <Modal open={showConfirm}>
        {confirmMessage || 'Confirmez vous votre choix ?'}
        <Modal.Footer>
          <div className="flex gap-4 w-full">
            <Button
              className="w-full text-2xl py-2 uppercase flex items-center justify-center gap-2"
              color="white-with-border"
              onClick={() => {
                confirmPromise?.reject();
              }}
            >
              Non
            </Button>
            <Button
              className="w-full text-2xl py-2 uppercase flex items-center justify-center gap-2"
              onClick={() => {
                confirmPromise?.resolve();
              }}
            >
              Oui
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {!!alerts && (
        <Modal
          open={!!alerts}
          footer={false}
          onClose={() => setAlerts(undefined)}
        >
          <div className="text-center">{alerts.message}</div>
        </Modal>
      )}
      {children}
    </CoreContext.Provider>
  );
};
