import { Etablissement, Webstore } from 'types/core';
import { axios } from 'utils/axios';

export const logas = (token: string) =>
  axios.get(`/security/authentication-support/jwt/logas/${token}`);

export const whoami = (): Promise<{
  data: {
    authenticated: boolean;
    etablissement: Etablissement;
    person: { oid: number };
  };
}> => axios.get(`/whoami`);

export const getWebStore = (
  oid: number,
): Promise<{ data: { element: Webstore } }> =>
  axios.get(`/modules/webstore/from-etablissement/${oid}`);

export const toggleWebStoreHS = async (
  oid: number,
  value: boolean,
): Promise<boolean> => {
  const data = await getWebStore(oid);
  const { element } = data.data;
  await axios.put(`/modules/webstore/etablissement/${element?.oid}`, {
    ...element,
    bornesData: {
      ...element.bornesData,
      parametreHorsService: value,
    },
  });

  return true;
};

export const getWebStoreMediaImageUrl = (filename: string): string | null => {
  return filename
    ? `${process.env.REACT_APP_SERVER_URL}modules/webstore/etablissement/download/media/${filename}`
    : null;
};
