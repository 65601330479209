import React from 'react';
import { CoreConfig, Etablissement } from 'types/core';

export type ConfigContextProps = {
  config?: CoreConfig;
  setConfig: (e: CoreConfig) => void;
  etablissement?: Etablissement;
  init: () => void;
  checkHS: () => void;
  whoami: () => void;
};

const BLANK: ConfigContextProps = {
  config: undefined,
  setConfig: () => new Error('setConfig not defined'),
  etablissement: undefined,
  init: () => new Error('init not defined'),
  checkHS: () => new Error('checkHS not defined'),
  whoami: () => new Error('whoami not defined'),
};

const ConfigContext = React.createContext(BLANK);

const useConfig = () => {
  const context = React.useContext(ConfigContext);
  return context;
};

export { ConfigContext, useConfig };
