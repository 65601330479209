import React, { SetStateAction } from 'react';
import {
  CommonCartInput,
  Invoice,
  InvoiceLine,
  NegativeLine,
} from 'types/checkout';
import { InfosTransaction } from 'types/core';

export type CoreContextProps = {
  basket?: Invoice;
  reset: () => Promise<void>;
  addProduct: (input: CommonCartInput) => void;
  negativeLines: NegativeLine[];
  removeNegativeLine: (e: string) => void;
  addCodePromo: (codePromo: string) => void;
  addChequeCadeau: (chequeCadeau: string) => Promise<boolean>;
  updateBasketLine: (e: InvoiceLine) => void;
  deleteBasketLine: (e: number | number[]) => void;
  confirm: (e: string) => Promise<boolean>;
  clientAuthenticated: boolean;
  setClientAuthenticated: React.Dispatch<SetStateAction<boolean>>;
  validateBasket: () => Promise<string>;
  getHardwareStatus: () => Promise<void>;
  sendAlert: (e: string) => void;
  saveTransaction: (data: InfosTransaction) => void;
};

const BLANK: CoreContextProps = {
  basket: undefined,
  reset: () => Promise.reject(new Error('reset not defined')),
  addProduct: () => new Error('addProduct not defined'),
  negativeLines: [],
  removeNegativeLine: () => new Error('removeNegativeLine not defined'),
  addCodePromo: () => new Error('addCodePromo not defined'),
  addChequeCadeau: () =>
    Promise.reject(new Error('addChequeCadeau not defined')),
  updateBasketLine: () => new Error('updateBasketLine not defined'),
  deleteBasketLine: () => new Error('deleteBasketLine not defined'),
  confirm: () => Promise.reject(new Error('confirm not defined')),
  clientAuthenticated: false,
  setClientAuthenticated: () => false,
  validateBasket: () => Promise.reject(new Error('validateBasket not defined')),
  getHardwareStatus: () =>
    Promise.reject(new Error('getHardwareStatus not defined')),
  sendAlert: () => new Error('sendAlert not defined'),
  saveTransaction: () =>
    Promise.reject(new Error('saveTransaction not defined')),
};

const CoreContext = React.createContext(BLANK);
const useCore = () => {
  const context = React.useContext(CoreContext);
  return context;
};

export { CoreContext, useCore };
