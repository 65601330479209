import React, { useEffect, useState } from 'react';
import { parse, isBefore, isAfter } from 'date-fns';

import {
  getElements,
  getPrestationsDescriptionsPersonnalisees,
} from 'features/catalog/stub';
import { getModePaiements } from 'features/checkout/stub';

import { formatCategories, formatProducts } from 'utils/products';
import { Category } from 'types/categories';
import {
  ElementsResponse,
  PrestationEtablissement,
  Product,
  Prestation,
} from 'types/products';
import { ModePaiement } from 'types/exploitation';
import { useAuth } from 'context/auth/auth.context';
import { useConfig } from 'context/config/config.context';
import { DataContext, FetchProductsParams } from './data.context';

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [modePaiements, setModePaiements] = useState<ModePaiement[]>([]);
  const { etablissement } = useConfig();

  const { client } = useAuth();

  useEffect(() => {
    fetchCatalog();
    handleFetchModePaiements();
    const intervalFetch = setInterval(() => {
      fetchCatalog();
    }, 1 * 60 * 60 * 1000);
    return () => {
      clearInterval(intervalFetch);
    };
  }, []);

  const fetchCatalog = async () => {
    let prestationsDescriptions: PrestationEtablissement[] = [];
    const { data } = await getElements();

    const prestationOids = (data.prestations as { oid: number }[]).map(
      (item: { oid: number }) => item.oid,
    );

    prestationsDescriptions = await getPrestationsDescriptionsPersonnalisees(
      etablissement?.oid || 0,
      { prestationOids },
    );

    const _elements: ElementsResponse = {
      ...data,
      prestations: data.prestations.map((item: Prestation) => {
        if (prestationsDescriptions.length) {
          const description = prestationsDescriptions.find(
            desc => desc.prestation.oid === item.oid,
          )?.description;

          return {
            ...item,
            borneElements: {
              ...item.borneElements,
              description: description || item.borneElements.description,
            },
          };
        }
        return item;
      }),
    };

    const products = formatProducts(_elements).filter(
      ({ borneElements: { dateAffichage, dateRetrait } }) => {
        const now = new Date();
        if (dateAffichage) {
          const parsedDateAffichage = parse(
            dateAffichage,
            'dd/MM/yyyy HH:mm:ss',
            now,
          );
          if (dateRetrait) {
            const parsedDateRetrait = parse(
              dateRetrait,
              'dd/MM/yyyy HH:mm:ss',
              now,
            );
            return (
              isBefore(now, parsedDateRetrait) &&
              isAfter(now, parsedDateAffichage)
            );
          }
          return isAfter(now, parsedDateAffichage);
        }
        return false;
      },
    ) as Product[];

    const productsCategories = products.reduce((acc, item) => {
      return [...acc, ...item.webCategories.map(({ oid }) => oid)];
    }, [] as number[]);
    const _categories = formatCategories(_elements) as Category[];

    const validAuthCategories = products.reduce((acc, item) => {
      if (item.clientAuthentifie) {
        return [...acc, ...item.webCategories.map(({ oid }) => oid)];
      }
      return acc;
    }, [] as number[]);

    const validNoAuthCategories = products.reduce((acc, item) => {
      if (!item.clientAuthentifie) {
        return [...acc, ...item.webCategories.map(({ oid }) => oid)];
      }
      return acc;
    }, [] as number[]);

    const finalCategories = _categories
      .map(item => {
        let _tmp = item;
        if (validAuthCategories.includes(item.oid))
          _tmp = { ..._tmp, auth: true };
        if (validNoAuthCategories.includes(item.oid))
          _tmp = { ..._tmp, noAuth: true };
        return _tmp;
      })
      .filter(item => {
        return productsCategories.includes(item.oid);
      });

    setCategories(finalCategories);
    setAllProducts(products);
  };

  const handleFetchCategory = (e: number) => {
    return categories.find(({ oid }) => oid === e);
  };

  const handleFetchModePaiements = async () => {
    const result = await getModePaiements({
      '_filter.etablissement': etablissement?.oid || 'all',
    });
    setModePaiements(result.content);
  };

  const handleFetchProducts = (e: FetchProductsParams) => {
    let _result = allProducts;

    if (e.category)
      _result = _result.filter(item => {
        return item.webCategories.some(({ oid }) => oid === e.category);
      });

    if (e.creditTemps)
      _result = _result.filter(
        item => item.type === 'prestation' && item.creditTemps,
      );

    _result = _result.filter(item =>
      client?.client?.clientPassage
        ? !item.clientAuthentifie
        : item.clientAuthentifie,
    );

    return _result;
  };

  return (
    <DataContext.Provider
      value={{
        categories,
        products: allProducts,
        modePaiements,
        fetchProducts: handleFetchProducts,
        fetchCategory: handleFetchCategory,
        refetch: fetchCatalog,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
