import { axios } from 'utils/axios';

import { PrestationEtablissement } from 'types/products';

export const getElements = () =>
  axios
    .get('/modules/webbornes/elements')
    .then(res => Promise.resolve(res))
    .catch(res => Promise.reject(res));

export const getPrestationsDescriptionsPersonnalisees = (
  etablissementOid: number,
  data: { prestationOids: number[] },
): Promise<PrestationEtablissement[]> => {
  return axios
    .post(
      `/modules/prestations/etablissement/${etablissementOid}/borneEtablissements`,
      data,
    )
    .then(({ data }) => Promise.resolve(data))
    .catch(res => Promise.reject(res));
};
